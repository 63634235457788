import React, { useState } from 'react';

export default function Navbar() {
    const [activeLink, setActiveLink] = useState('#about');

    const handleLinkClick = (href) => {
        setActiveLink(href);
    };

    return (
        <nav id="nav" className="navbar navbar-expand navbar-dark bg-dark fixed-top mr-auto">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a
                        className={`nav-link ${activeLink === '#about' ? 'active' : ''}`}
                        aria-current="page"
                        href="#about"
                        onClick={() => handleLinkClick('#about')}
                    >
                        About Me
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${activeLink === '#projects' ? 'active' : ''}`}
                        href="#projects"
                        onClick={() => handleLinkClick('#projects')}
                    >
                        Projects
                    </a>
                </li>
            </ul>
        </nav>
    );
}