import {FaPython, FaJava, FaReact, FaNodeJs, FaAngular, FaLinux, FaRust} from 'react-icons/fa'
import {DiJavascript1, DiGitBranch, DiJqueryLogo} from "react-icons/di"
export const projects = [
    {
        title:"Tempest Type",
        description: "Multiplayer typing game with real-time competitive races. Backend in Rust utilizing actix-web for communications.",
        tech: [<FaRust/>, <FaReact />, <DiJavascript1/>],
        link: "https://tempesttype.xyz/"
    },
    {
        title:"Pet Adoption",
        description: "Sample platform for a pet adoption service, supports standard CRUD operations and persistent data management.",
        tech: [<FaReact />, <DiJavascript1/>, <FaNodeJs label='node-js'/>],
        link: "https://somecountyshelter.xyz/"
    },
    {
		title:"Nearby Planes",
		description: "Reveals nearby planes with route, carrier, progress, and more. Utilizes AirLabs API and Google Maps JS API via Express backend",
		tech: [<FaReact />, <DiJavascript1/>, <FaNodeJs label='node-js'/>, <DiJqueryLogo/>],
		link: "https://nearby-planes.herokuapp.com/"
	},
    {
        title: "Personal Portfolio",
        description: "My portfolio to host current and future projects",
        tech: [<FaReact />, <DiJavascript1/>],
        link: "#"
    },
    {
        title: "Productivity Timer",
        description: "A pomodoro-based productivity timer",
        tech: [<FaReact />, <DiJavascript1/>],
        link: "https://chris-dennis.github.io/productivity-timer"
    },

];

export const skills = [
    {
        name: "Python",
        icon: <FaPython/>
    },
    {
        name: "Java",
        icon: <FaJava/>
    },
    {
        name: "JavaScript",
        icon: <DiJavascript1/>
    },
    {
        name: "React",
        icon: <FaReact/>
    },
    {
        name: "Angular",
        icon: <FaAngular/>
    },
    {
        name:"Git",
        icon:<DiGitBranch/>
    },
    {
        name:"Linux",
        icon:<FaLinux/>
    }
];

export const courses = [
    'Data Structures and Algorithms',
    'Discrete Math and Functional Programming',
    'Algorithms',
    'Natural Language Processing',
    'Programming Languages and Theory',
    'Game Engine Programming',
    'Computer Systems',
    'System Security',

];