import { courses, skills } from "./data"
import {AiOutlineMail} from "react-icons/ai";
import {FaFreeCodeCamp, FaGithub, FaLinkedin, FaDownload} from "react-icons/fa";
import CodeSVG from "../code.svg"
export default function About() {

    return (
        <section id='about'>
            <div id='about-contact'>
                <div id='contact-wrapper'>
                    <img id="codesvg" src={CodeSVG} alt="Profile" />
                    <div id='contact-links'>
                        <a href="mailto:chris@cdennis.dev" target="_blank" rel="noreferrer"><AiOutlineMail className='contactIcon'/></a>
                        <a href="https://www.linkedin.com/in/c-dennis/" target="_blank" rel="noreferrer"><FaLinkedin className='contactIcon'/></a>
                        <a href="https://github.com/chris-dennis" target="_blank" rel="noreferrer"><FaGithub className='contactIcon'/></a>
                        <a href="https://www.freecodecamp.org/chrisdv" target="_blank" rel="noreferrer"><FaFreeCodeCamp className='contactIcon'/></a>
                    </div>
                </div>

                <div id='about-me-text'>
                    <div>
                        <h1 id="name">Christopher Dennis</h1>
                        <ul id='about-me-ul'>
                            <li>Full Stack Development</li>
                            <li><a href="https://www.pomona.edu/" target="_blank" rel="noreferrer">Pomona College</a> 2024</li>
                            <li><a href="https://www.questbridge.org/" target="_blank" rel="noreferrer"> QuestBridge</a> College Match Scholarship Recipient</li>
                            <li>Previous Intern at <a href="https://www.seic.com/" target="_blank" rel="noreferrer">SEI Investments</a> Summer 2023</li>
                            <li>Download Resume <a id="download" href="Resume CD.docx" download>.docx<FaDownload /></a> <a id="download" href="Resume CD.docx.pdf" download> .pdf <FaDownload /></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id='skills-courses'>
                <div id='skills'>
                    {skills.map((skills) => (
                        <div id="skill"><div>{skills.name}</div> <div id='skills-icon'>{skills.icon}</div></div>
                    ))}

                </div>
                <div id='courses'>
                    {courses.map((courses) => (
                        <div id='skill'>{courses}</div>
                    ))}
                </div>
            </div>
        </section>
    )
}